import React, { useEffect, useRef } from "react";
import {
  AboutLeft,
  AboutPuzzle,
  CartUpper,
  CartDown,
  HomeHeader,
  Cartton,
  random1,
  random3,
} from "../../assets";
import MainLayout from "../../layouts/MainLayout";
import { FaAngleUp, FaAngleDown } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

const Home = () => {
  const [CartCount, setCartCount] = React.useState(1);

  const aboutRef = useRef(null);
  const productRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const sectionRef =
        location.state.scrollTo === "about" ? aboutRef : productRef;

      const scrollToSection = () => {
        if (sectionRef.current) {
          sectionRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
          console.error("Element not found:", location.state.scrollTo);
        }
      };

      const timer = setTimeout(scrollToSection, 100);
      return () => clearTimeout(timer);
    }
  }, [location.state]);

  return (
    <MainLayout>
      {/* /// header start  */}
      <div className=" w-full  overflow-hidden relative">
        <img
          src={HomeHeader}
          className="w-full h-auto object-cover"
          alt="HomeHeader.svg"
        />
        <div className="h-full w-full absolute top-0 left-0">
          <h1 className="MainHead text-secondary relative md:top-36 top-16 text-center text-4xl md:text-7xl lg:text-[135px]">
            PUZZLE BUBS
          </h1>
        </div>
      </div>
      {/* /// header end  */}

      {/* /// About us start */}

      <div
        ref={aboutRef}
        id="about"
        className="sm:w-[80%] w-[90%] m-auto pt-10 "
      >
        <div className="relative p-3 w-max m-auto flex justify-center">
          <img
            src={AboutLeft}
            className="absolute -left-6 -top-6"
            alt="AboutLeft.svg"
          />
          <img
            src={AboutPuzzle}
            className="absolute -top-10"
            alt="AboutPuzzle.svg"
          />
          <h1 className=" text-secondary AboutHead text-center lg:text-7xl text-5xl font-magical leading-[72px] tracking-[0.144px] font-normal">
            About
          </h1>
        </div>
        <p className="text-center font-quicksand text-lg font-semibold leading-7 tracking-[-0.54px] text-[#777980] py-4">
          Puzzle Bubs are a world of cuteness waiting to be explored. They're a
          line of cube-shaped, foam-filled, mix-and-matchable jigsaw plushies,
          letting you piece together your special companions, Mix and match
          their heads and bodies to create unique cuddly Bubs, each with its own
          special scent, playful crinkle, and collectible Norsom hang tag. All
          Puzzle Bubs are perfectly filled with fluffy foam, ensuring no Bub is
          ever under- or over-stuffed. Start your mix-and-matchable, absolutely
          adorable Puzzle Bubs collection.
        </p>
        {/* <button className="text-[#1D1F2C] font-quicksand py-[10px] px-6 bg-secondary rounded-[10px] block m-auto transition-all duration-300 hover:bg-secondary-dark hover:text-white hover:shadow-lg">
          Explore More
        </button> */}
      </div>

      {/* /// About us end */}

      {/* /// Cart Box Start */}

      <div
        ref={productRef}
        id="product"
        className="w-full relative bg-[#FFF23E]"
      >
        <img
          className="h-auto absolute bottom-0 left-0 w-full object-cover"
          src={CartUpper}
          alt="cardback.svg"
        />
        <div className="lg:w-[85%] w-[90%] lg:py-32 md:pt-10 md:pb-32 py-10  grid lg:grid-cols-2 grid-cols-1 m-auto relative z-50">
          <div className=" lg:py-5">
            <img className="h-full" src={Cartton} alt="Cartton.svg" />
          </div>
          <div className="lg:py-2 relative">
            <div className="lg:pt-10 lg:pb-0 pb-5 flex flex-col gap-[24px] ">
              <h1 className="cartHeading relative text-primary sm:font-normal font-extralight font-magical lg:text-[76px] sm:text-6xl text-5xl m-0">
                Caroline The Cow
              </h1>
              <p className="text-[#1D1F2C] font-quicksand font-bold text-lg leading-9 tracking-[-0.63px]">
                Caroline the Cow is the first Puzzle Bub of the bunch. She's
                sweet, cute, and smells like vanilla too. Her uniquely cubed,
                foam-filled design. perfectly pink crinkly nose, and utter will
                have you mooing for more. Caroline is an utterly wonderful
                addition to any plushie collection.
              </p>
              {/* <div className="font-quicksand">
                <p className="sm:text-lg text-base text-[#1D1F2C] font-medium leading-8">
                  <span className="font-bold">Size:</span>6in X 6in X 12in
                </p>
                <p className="sm:text-lg text-base text-[#1D1F2C] font-medium leading-8">
                  <span className="font-bold">Made With:</span> Polyester
                  fabric. Memory foam (polyurethane) stuffing, vanilla smell,
                  crinkle nose and utter.
                </p>
                <p className="sm:text-lg text-base text-[#1D1F2C] font-medium leading-8">
                  <span className="font-bold">Includes:</span>Norsom hangtag
                  puzzle poem story.
                </p>
              </div> */}
              <h1 className="text-[#EA3932] font-quicksand sm:text-4xl text-3xl font-bold leading-9">
                <span>$</span>19.99
              </h1>
              <div className="py-4 flex gap-4 font-quicksand">
                <div className="px-6 pr-10 relative text-2xl border-4 rounded-[10px] text-white font-bold py-3 border-[#1D1F2C] w-max">
                  <span className="text-[#1D1F2C]">{CartCount}</span>
                  <div className="absolute top-1 right-1 bg-[#ffffff4a] border border-[#eeeeee4a]">
                    <FaAngleUp
                      onClick={() => setCartCount(CartCount + 1)}
                      className="border border-[#eeeeee4a] text-[#1D1F2C] cursor-pointer"
                    />
                    <FaAngleDown
                      onClick={() => setCartCount(CartCount - 1)}
                      className="border border-[#eeeeee4a] text-[#1D1F2C] cursor-pointer"
                    />
                  </div>
                </div>
                <button className="py-4 px-8 bg-primary w-full text-white rounded-[10px] text-[22px] font-bold leading-[22px] tracking-[-0.66px] transition-all duration-300 hover:bg-primary-dark hover:scale-105 hover:shadow-lg">
                  Buy
                </button>
              </div>
              <h1 className="text-[#1D1F2C] font-quicksand sm:text-[22px] text-base font-bold leading-[22px] tracking-[-0.66px]">
                *FREE USA GROUND SHIPPING AVAILABLE
              </h1>
            </div>
          </div>
          <div className="w-full lg:px-10 pb-4 relative font-quicksand">
            <h1 className="text-primary text-[26px] font-bold leading-6 tracking-[-0.78px] border-b border-primary py-1 w-max ">
              Details
            </h1>
            <ul className="px-5 pt-3">
              <li className="text-xl text-[#1D1F2C] font-semibold leading-8 list-disc">
                Size: 12in
              </li>
              <li className="text-xl text-[#1D1F2C] font-semibold leading-8 list-disc">
                Stuffed with fluffy foam
              </li>
              <li className="text-xl text-[#1D1F2C] font-semibold leading-8 list-disc">
                Surface clean
              </li>
              <li className="text-xl text-[#1D1F2C] font-semibold leading-8 list-disc">
                Norsom hangtag with collectable artwork
              </li>
              <li className="text-xl text-[#1D1F2C] font-semibold leading-8 list-disc">
                Free shipping
              </li>
            </ul>
          </div>
        </div>
        <img src={random1} className="absolute bottom-60 right-96" alt="" />
        <img
          src={random3}
          className="absolute bottom-72 right-[500px]"
          alt=""
        />
        <img
          className="h-auto absolute top-0 left-0 w-full object-cover"
          src={CartDown}
          alt="cardback.svg"
        />
      </div>

      {/* /// Cart Box end */}
      {/* <div className="md:w-1/2 sm:w-3/4 w-[90%] m-auto py-16">
        <h1 className=" text-secondary AboutHead text-center lg:text-6xl text-5xl font-magical leading-[80px] tracking-[0.144px] font-normal">
          Please feel free to call or email rain or shine, day or night
        </h1>
      </div> */}
    </MainLayout>
  );
};

export default Home;
