import React from "react";
import MainLayout from "../../layouts/MainLayout";
import {
  AboutPuzzle,
  Contactmain,
  Puzzle1,
  Puzzle2,
  Puzzle3,
  Puzzle4,
  Puzzle5,
  Puzzle6,
  yellow,
} from "../../assets";

const PrivacyPolicy = () => {
  return (
    <MainLayout>
      {/* //// Header start */}
      <div className="w-full relative">
        <img
          src={Contactmain}
          className="h-auto w-full object-cover"
          alt="Contactmain.svg"
        />
        <div className="absolute w-full h-full top-0 flex items-center justify-center">
          <h1 className="HeaderStroke font-magical text-secondary text-5xl md:text-[120px] xl:text-[187.26px] font-normal leading-[100%] tracking-[0.375px]">
            Privacy Policies
          </h1>
        </div>
      </div>

      {/* //// Header end */}

      {/* //// Contact us start */}
      <div className="py-20">
        <div className="md:w-[70%] w-[90%] m-auto">
        <div className="w-full relative flex justify-center">
          <img
            src={AboutPuzzle}
            className="absolute -top-16"
            alt="AboutPuzzle.svg"
          />
          <img src={yellow} className=" absolute left-[35%] -top-10"  alt="yellow.svg" />
            <h1 className="text-center z-10 secondarystroke font-magical md:text-7xl text-4xl text-secondary font-normal leading-[100%] tracking-[0.144px]">
            Privacy we provide
            </h1>
          </div>
          <div className="flex flex-col font-quicksand items-center gap-2 justify-center py-6">
            <p className="text-[#777980] text-center text-lg font-semibold leading-[120%] tracking-[-0.54px]">
              Protecting Your Data, Ensuring Your Trust.
            </p>
          </div>
        </div>
        <div className="w-[90%] m-auto font-quicksand grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[72px_24px]">
          <div className="flex items-start gap-2">
            <img src={Puzzle1} alt="puzzle1.svg" />
            <div className="flex flex-col gap-2">
              <h1 className="text-[#295ABA] text-[32px] font-bold leading-normal tracking-[-1.12px]">
                Data Collection
              </h1>
              <p className="text-lg font-normal leading-[26px] tracking-[-0.63px] text-[#4A4C56)]">
                We collect personal information such as names, email addresses,
                and contact details when you interact with our services,
                including making purchases or signing up for newsletters
              </p>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <img src={Puzzle2} alt="puzzle1.svg" />
            <div className="flex flex-col gap-2">
              <h1 className="text-[#295ABA] text-[32px] font-bold leading-normal tracking-[-1.12px]">
                Use of Information
              </h1>
              <p className="text-lg font-normal leading-[26px] tracking-[-0.63px] text-[#4A4C56)]">
                Your personal information is used solely to process orders,
                provide customer support, and enhance your experience with
                Norsom LLC. We do not sell or share your information with third
                parties without your consent
              </p>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <img src={Puzzle3} alt="puzzle1.svg" />
            <div className="flex flex-col gap-2">
              <h1 className="text-[#295ABA] text-[32px] font-bold leading-normal tracking-[-1.12px]">
                Data Security
              </h1>
              <p className="text-lg font-normal leading-[26px] tracking-[-0.63px] text-[#4A4C56)]">
                We implement robust security measures to protect your personal
                information from unauthorized access, disclosure, or alteration
              </p>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <img src={Puzzle4} alt="puzzle1.svg" />
            <div className="flex flex-col gap-2">
              <h1 className="text-[#295ABA] text-[32px] font-bold leading-normal tracking-[-1.12px]">
                Cookies and Tracking
              </h1>
              <p className="text-lg font-normal leading-[26px] tracking-[-0.63px] text-[#4A4C56)]">
                Our website may use cookies to enhance user experience, track
                site usage, and improve our services. You can adjust your
                browser settings to manage cookie preferences
              </p>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <img src={Puzzle5} alt="puzzle1.svg" />
            <div className="flex flex-col gap-2">
              <h1 className="text-[#295ABA] text-[32px] font-bold leading-normal tracking-[-1.12px]">
                Third-Party Services
              </h1>
              <p className="text-lg font-normal leading-[26px] tracking-[-0.63px] text-[#4A4C56)]">
                We may partner with third-party service providers for payment
                processing and shipping. These providers have access to your
                information only to the extent necessary to perform their
                functions
              </p>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <img src={Puzzle6} alt="puzzle1.svg" />
            <div className="flex flex-col gap-2">
              <h1 className="text-[#295ABA] text-[32px] font-bold leading-normal tracking-[-1.12px]">
                Your Rights
              </h1>
              <p className="text-lg font-normal leading-[26px] tracking-[-0.63px] text-[#4A4C56)]">
                You have the right to access, update, or request the deletion of
                your personal information at any time. Please contact us at
                <span className="text-[#EB3D4D] font-semibold">
                  {" "}
                  ethanm@norsom.com
                </span>{" "}
                for any privacy-related inquiries
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* //// Contact us end */}
    </MainLayout>
  );
};

export default PrivacyPolicy;
