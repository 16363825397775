import React, { useState, useEffect } from "react";
import { Logo } from "../../assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const NavbarLists = [
    {
      id: 1,
      name: "Home",
      link: "/",
    },
    {
      id: 2,
      name: "About",
      link: "/",
      scrollTo: "about",
    },
    {
      id: 3,
      name: "Product",
      link: "/",
      scrollTo: "product",
    },
    {
      id: 4,
      name: "Contact Us",
      link: "/contact-us",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  const handleNavigation = (scrollTo) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo }, replace: true });
      setMenuOpen(false);
    } else if (scrollTo) {
      document.getElementById(scrollTo)?.scrollIntoView({ behavior: "smooth" });
      setMenuOpen(false);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.id === "overlay") {
      setMenuOpen(false);
    }
  };

  return (
    <div
      className={`h-20 w-full flex items-center lg:px-0 px-4 justify-between lg:justify-around fixed z-[100] transition-all duration-300 ${
        scrolled
          ? "bg-[#ffffff70] backdrop-filter backdrop-blur-lg shadow-md"
          : ""
      }`}
    >
      <div className="lg:w-32">
        <Link to="/">
          <img className="h-auto sm:w-auto w-[80%]" src={Logo} alt="Logo.svg" />
        </Link>
      </div>
      <div className="lg:w-[55%]">
        <ul className="lg:flex gap-10 w-max hidden font-quicksand">
          {NavbarLists.map((list) => (
            <li
              key={list.id}
              className={` ${
                scrolled ? "text-black" : "text-white"
              } hover:text-secondary duration-200 `}
            >
              <Link
                to={list.link}
                onClick={() => handleNavigation(list.scrollTo)}
              >
                {list.name}
              </Link>
            </li>
          ))}
        </ul>
        <FiMenu
          className="text-black text-3xl lg:hidden block cursor-pointer"
          onClick={() => setMenuOpen(true)}
        />
      </div>

      {/* Mobile Menu */}
      <div
        id="overlay"
        className={`h-screen fixed z-[100] font-quicksand top-0 left-0 bg-[#00000085] w-full transition-all duration-300 ${
          menuOpen ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={handleOverlayClick}
      >
        <div
          className={`w-[80%] sm:w-1/2 h-full bg-white py-10 px-4 transform transition-transform duration-300 ${
            menuOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <ul className="flex flex-col gap-10 mt-10">
            {NavbarLists.map((list) => (
              <li key={list.id} className="text-black font-bold">
                <Link
                  to={list.link}
                  onClick={() => handleNavigation(list.scrollTo)}
                >
                  {list.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
