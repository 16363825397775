const HomeHeader = "/assets/images/MainHeader.svg";
const Logo = "/assets/images/Logo.svg";
const AboutLeft = "/assets/images/About left.svg";
const AboutPuzzle = "/assets/images/Abouth Puzzle.svg";
const Cartton = "/assets/images/Cartoon.svg";
const CartUpper = "/assets/images/Cart Down.svg";
const CartDown = "/assets/images/Cart Upper.svg";
const Fotterback = "/assets/images/Fotter back.svg";
const socailinnrer = "/assets/images/social inner.svg";
const Telegraminnrer = "/assets/images/Telegram inner.svg";
const Instainnrer = "/assets/images/Insta Inner.svg";
const Tiktok = "/assets/images/Tiktok.svg";
const Telegram = "/assets/images/Telegram.svg";
const Instagram = "/assets/images/instagram.svg";
const FooterArrow = "/assets/images/Arrow footer.svg";
const FooterCartton = "/assets/images/Footer Cartoon.svg";
const Contactmain = "/assets/images/contact main.svg";
const Star = "/assets/images/star.svg";
const Shippingup = "/assets/images/shipping up.svg";
const ShippingDown = "/assets/images/shipping down.svg";
const Puzzle1 = "/assets/images/puzzle 1.svg";
const Puzzle2 = "/assets/images/puzzle 2.svg";
const Puzzle3 = "/assets/images/puzzle 3.svg";
const Puzzle4 = "/assets/images/puzzle 4.svg";
const Puzzle5 = "/assets/images/puzzle 5.svg";
const Puzzle6 = "/assets/images/puzzle 6.svg";
const yellow = "/assets/icons/yellow circle.svg";
const random1 = "/assets/icons/random 1.svg";
const random2 = "/assets/icons/random 2.svg";
const random3 = "/assets/icons/random3.svg";
const random4 = "/assets/icons/random 4.svg";

export {
  HomeHeader,
  Logo,
  AboutLeft,
  AboutPuzzle,
  CartUpper,
  CartDown,
  Cartton,
  Fotterback,
  socailinnrer,
  Tiktok,
  Telegram,
  Instagram,
  FooterArrow,
  Telegraminnrer,
  Instainnrer,
  FooterCartton,
  Contactmain,
  Shippingup,
  ShippingDown,
  Star,
  Puzzle1,
  Puzzle2,
  Puzzle3,
  Puzzle4,
  Puzzle5,
  Puzzle6,
  yellow,
  random1,
  random2,
  random3,
  random4,
};
