import React from "react";
import MainLayout from "../../layouts/MainLayout";
import {
  AboutPuzzle,
  Contactmain,
  random1,
  random2,
  random3,
  random4,
  ShippingDown,
  Shippingup,
  Star,
  yellow,
} from "../../assets";

const Contact = () => {
  return (
    <MainLayout>
      {/* //// Header start */}
      <div className="w-full relative">
        <img
          src={Contactmain}
          className="h-auto w-full object-cover"
          alt="Contactmain.svg"
        />
        <div className="absolute w-full h-full top-0 flex items-center justify-center">
          <h1 className="HeaderStroke font-magical text-secondary text-5xl lg:text-[187.26px] font-normal leading-[100%] tracking-[0.375px]">
            Contact Us
          </h1>
        </div>
      </div>

      {/* //// Header end */}

      {/* //// Contact us start */}
      <div className="py-20 relative font-quicksand">
        <img
          src={random1}
          className=" absolute md:right-40 right-3 md:top-12 top-2"
          alt="random1.svg"
        />
        <img
          src={random2}
          className=" absolute right-24 bottom-12"
          alt="random1.svg"
        />
        <img
          src={random3}
          className=" absolute right-8 top-96"
          alt="random1.svg"
        />
        <img
          src={random4}
          className=" absolute right-44 top-96"
          alt="random1.svg"
        />

        <img
          src={random3}
          className=" absolute left-36 top-60"
          alt="random1.svg"
        />
        <img
          src={random4}
          className=" absolute right-96 bottom-0"
          alt="random1.svg"
        />
        <img
          src={random4}
          className=" absolute md:left-44 left-4 md:top-32 top-44"
          alt="random1.svg"
        />
        <img
          src={random1}
          className=" absolute left-24 top-[500px]"
          alt="random1.svg"
        />

        <div className="md:w-[70%] w-[90%] m-auto">
          <div className="w-full relative flex justify-center">
            <img
              src={yellow}
              className=" absolute left-[35%] -top-10"
              alt="yellow.svg"
            />

            <img
              src={AboutPuzzle}
              className="absolute -top-16"
              alt="AboutPuzzle.svg"
            />

            <h1 className="text-center secondarystroke font-magical md:text-7xl text-4xl text-secondary font-normal leading-[100%] tracking-[0.144px]">
              Want to get in touch?
            </h1>
          </div>
          <div className="flex flex-col items-center gap-2 justify-center py-6 relative z-20">
            <p className="text-[#777980] text-center text-lg font-semibold leading-[120%] tracking-[-0.54px]">
              Contact Us At{" "}
              <span className="text-[#295ABA] font-bold">
                contact@norsomllc.com
              </span>
            </p>
            <p className="text-[#777980] text-center text-lg font-semibold leading-[120%] tracking-[-0.54px]">
              or
            </p>
            <p className="text-[#777980] text-center text-lg font-semibold leading-[120%] tracking-[-0.54px]">
              Send Us Your Email
            </p>
          </div>
          <div className="w-full p-[32px_26px] border border-[#EBEBEB] rounded-[10px] bg-[#F2F6FB] relative z-20">
            <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-6">
              <input
                type="text"
                placeholder="First Name"
                className="w-full border col-span-1 border-[#F0F0F0] bg-white outline-none rounded-[10px] p-[10px_28px] text-[#D2D2D5] text-lg font-medium leading-7 tracking-[-0.48px]"
              />
              <input
                type="text"
                placeholder="Last Name"
                className="w-full border col-span-1 border-[#F0F0F0] bg-white outline-none rounded-[10px] p-[10px_28px] text-[#D2D2D5] text-lg font-medium leading-7 tracking-[-0.48px]"
              />
              <input
                type="number"
                placeholder="Phone Number"
                className="w-full border col-span-1 border-[#F0F0F0] bg-white outline-none rounded-[10px] p-[10px_28px] text-[#D2D2D5] text-lg font-medium leading-7 tracking-[-0.48px]"
              />
              <input
                type="email"
                placeholder="Email Address"
                className="w-full border col-span-1 border-[#F0F0F0] bg-white outline-none rounded-[10px] p-[10px_28px] text-[#D2D2D5] text-lg font-medium leading-7 tracking-[-0.48px]"
              />
              <textarea
                type="email"
                placeholder="Message..."
                className="w-full border md:col-span-2 col-span-1 h-[150px] resize-none border-[#F0F0F0] bg-white outline-none rounded-[10px] p-[17px_28px] text-[#D2D2D5] text-lg font-medium leading-7 tracking-[-0.48px]"
              />
              <button className="w-full hover:scale-[1.01] hover:shadow-lg duration-300 md:col-span-2 col-span-1 bg-[#8BB4F0] rounded-[10px] p-[16px_32px] text-white text-lg font-bold leading-[22px] tracking-[-0.66px]">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* //// Contact us end */}

      {/* //// shpping start */}

      <div className="md:py-56 py-16 relative sm:mb-44 mb-10 z-10 bg-[#8BB4F0]">
        <img
          src={Shippingup}
          className="absolute w-full left-0 top-0 z-20"
          alt=""
        />
        <div className=" overflow-hidden relative z-30 w-full flex items-center justify-center">
          <div className=" md:w-[70%] sm:w-[80%] w-[90%] flex flex-col justify-end relative z-10">
            <h1 className="text-[#FBED2C] text-center font-magical md:text-6xl text-3xl font-normal sm:leading-[96px] leading-[50px] tracking-[ 0.12px] shppingshadow">
              Shipping Made Simple! <br /> We Deliver Right to Your Doorstep
            </h1>
            <div className="flex justify-center flex-wrap gap-3 font-quicksand">
              <div className="flex gap-[10px]">
                <img src={Star} alt="Star.svg" />
                <span className="text-white text-[20px] font-semibold leading-normal tracking-[-.7px]">
                  US Shipping Only
                </span>
              </div>
              <div className="flex gap-[10px]">
                <img src={Star} alt="Star.svg" />
                <span className="text-white text-[20px] font-semibold leading-normal tracking-[-.7px]">
                  Full Money-Back Guarantee
                </span>
              </div>
            </div>
            <p className="text-white font-quicksand py-2 text-center text-[20px] font-semibold leading-normal tracking-[-.7px]">
              Email us at{" "}
              <span className="text-secondary font-bold">
                contact@norsomllc.com
              </span>{" "}
              for any shipping concerns
            </p>
          </div>
        </div>{" "}
        <img
          className="absolute w-full left-0 bottom-0 z-10"
          src={ShippingDown}
          alt="contactsub.svg"
        />
      </div>

      {/* //// shpping end */}
    </MainLayout>
  );
};

export default Contact;
