import React from "react";
import { Route, Routes } from "react-router-dom";
import { Contact, Home, PrivacyPolicy } from "./pages";
import useScrollToTop from "./components/scrolltop";

const App = () => {
  useScrollToTop();
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default App;
