import React from "react";
import {
  FooterArrow,
  FooterCartton,
  Fotterback,
  Instagram,
  Instainnrer,
  socailinnrer,
  Telegram,
  Telegraminnrer,
  Tiktok,
} from "../../assets";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full relative bg-[#8BB4F0] ">
      <img
        className="h-auto absolute -top-[1px] left-0 w-full object-cover"
        src={Fotterback}
        alt="Fotterback.svg"
      />
      <div className="xl:w-[90%] w-full relative z-20">
        <div className=" xl:w-[80%] w-[90%] pt-20 relative m-auto grid sm:grid-cols-4 grid-cols-1">
          <div className="sm:pt-56 pt-10 col-span-1">
            <h1 className="text-secondary font-magical text-[46px] font-normal leading-10 tracking-[1.38px]">
              About
            </h1>
            <ul className="pt-4">
              <li className="text-white font-magical text-[28px] hover:text-secondary duration-200 cursor-pointer font-normal leading-10 tracking-[0.853px]">
                <Link to={"/privacy-policy"}>your pricvacy</Link>
              </li>
              <li className="text-white font-magical text-[28px] hover:text-secondary duration-200 cursor-pointer font-normal leading-10 tracking-[0.853px]">
                terms & Conditions
              </li>
              <li className="text-white font-magical text-[28px] hover:text-secondary duration-200 cursor-pointer font-normal leading-10 tracking-[0.853px]">
                terms of service{" "}
              </li>
            </ul>
          </div>
          <div className="sm:pt-56 pt-10 col-span-1">
            <h1 className="text-secondary font-magical text-[46px] font-normal leading-10 tracking-[1.38px]">
              Support
            </h1>
            <ul className="pt-4">
              <li className="text-white font-magical text-[28px] hover:text-secondary duration-200 cursor-pointer font-normal leading-10 tracking-[0.853px]">
                help
              </li>
              <li className="text-white font-magical text-[28px] hover:text-secondary duration-200 cursor-pointer font-normal leading-10 tracking-[0.853px]">
                shipping
              </li>
              <li className="text-white font-magical text-[28px] hover:text-secondary duration-200 cursor-pointer font-normal leading-10 tracking-[0.853px]">
                returns
              </li>
              <li className="text-white font-magical text-[28px] hover:text-secondary duration-200 cursor-pointer font-normal leading-10 tracking-[0.853px]">
                contact us
              </li>
            </ul>
          </div>
          <div className="sm:col-span-2 col-span-1  pt-16">
            <div className="flex gap-7 rotate-[-6.574deg]">
              <div className="socialhadow cursor-pointer relative hover: transform transition-transform duration-300 hover:scale-110 flex items-center justify-center h-[93px] w-[93px] rounded-[13px] bg-white overflow-hidden">
                <Link to="https://www.tiktok.com/@norsom.official?_t=8pSONgrlOxi&_r=1" target="_blank">
                  <img
                    className="absolute top-0 left-0 "
                    src={socailinnrer}
                    alt="socailinnrer.svg"
                  />
                  <img src={Tiktok} alt="Tiktok.svg" />
                </Link>
              </div>
              <div className="socialhadow cursor-pointer relative hover: transform transition-transform duration-300 hover:scale-110 flex items-center justify-center h-[93px] w-[93px] rounded-[13px] bg-white overflow-hidden">
                <img
                  className="absolute top-0 left-0 "
                  src={Telegraminnrer}
                  alt="socailinnrer.svg"
                />
                <img src={Telegram} alt="Tiktok.svg" />
              </div>
              <div className="socialhadow cursor-pointer relative hover: transform transition-transform duration-300 hover:scale-105 flex items-center justify-center h-[93px] w-[93px] rounded-[13px] bg-white overflow-hidden">
                <Link to="https://www.instagram.com/norsomllc?igsh=dTR2emtubGJ0Y25j" target="_blank">
                  <img
                    className="absolute top-0 right-0 "
                    src={Instainnrer}
                    alt="socailinnrer.svg"
                  />
                  <img src={Instagram} alt="Tiktok.svg" />
                </Link>
              </div>
            </div>
            <div className="flex justify-center sm:w-[74%] pt-10">
              <img src={FooterArrow} alt="" />
            </div>
            <div className="p-[17.149px_47.159px] cursor-pointer my-5 Socialshadow2 bg-white rounded-2xl hover: transform transition-transform duration-300 hover:scale-110 w-max flex items-center justify-center">
              <h1 className="text-[#8BB4F0] font-magical sm:text-[34px] text-3xl font-normal">
                Retailer? Click here!
              </h1>
            </div>
            <Link to="/contact-us">
              <div className="p-[17.149px_47.159px] cursor-pointer mx-10 Socialshadow2 bg-white rounded-2xl hover: transform transition-transform duration-300 hover:scale-110 w-max flex items-center justify-center">
                <h1 className="text-[#8BB4F0] font-magical sm:text-[34px] text-3xl font-normal">
                  click TO Contact Us
                </h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <img
        src={FooterCartton}
        className=" absolute right-0 bottom-0 hidden md:block"
        alt="FooterCartton.svg"
      />
      <h1 className="text-secondary relative py-10 text-center font-magical text-[28px] font-normal leading-10 tracking-[0.853px]">
        &copy; 2024 Norsom ALL RIGHTS RESERVED
      </h1>
    </div>
  );
};

export default Footer;
